<template>
  <div class="loan-containter">
    <nav-menu></nav-menu>
    <div class="loan-body">
      <div class="loan-swiper">
        <el-carousel trigger="click">
          <el-carousel-item v-for="item in list" :key="item.id">
            <h2 class="small">{{ item.title }}</h2>
            <p>{{ item.content }}</p>
            <div class="btns">查看详情</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="loan-list flex-a">
        <div class="title">产品类别</div>
        <div :class="tab == 1 ? 'loan-item active' : 'loan-item'" @click="changeTab(1)">电子银行</div>
      </div>
      <div class="containter flex">
        <div class="bank-list">
          <div class="bank-item" v-for="item in bankList" :key="item.id">
            <div><img :src="item.src" alt="" /></div>
            <div class="text">{{ item.content }}</div>
            <div><span @click="checkDetail">查看</span></div>
          </div>
          <div class="page-list">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="20">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
export default {
  name: 'loan',
  data() {
    return {
      list: [
        {
          id: 1,
          title: '招商银行融资产品',
          content: '招商银行针对中标客户提供的重点融资产品，快捷，高效，缓解中小企业流动资金紧张困难。'
        },
        {
          id: 2,
          title: '建设银行融资产品',
          content: '建行银行为中标企业签订合同后专项用于项目所需资金的融资服务， 快捷高效、费低额高。'
        },
        {
          id: 3,
          title: '长沙银行融资产品',
          content: '长沙银行为长沙公共资源交易金融服务平台提供的多品类融资服务，申请方便快捷、额度高、成本低、审批快。'
        },
        {
          id: 4,
          title: '东莞银行融资产品',
          content: '免开户、免保证金、纯信用、额度高、可循环、操作简便、资料简单。留下联系方式后，全程我行协助操作，单笔手续费不超过5‰。'
        }
      ],
      tab: 1,
      bankList: [
        {
          id: 1,
          src: require('./../assets/csbank.png'),
          content: '长沙银行为长沙公共资源交易金融服务平台提供的多品类融资服务，申请方便快捷、额度高、成本低、审批快。'
        },
        {
          id: 2,
          src: require('./../assets/main_logo.gif'),
          content: '招商银行针对中标客户提供的重点融资产品，快捷，高效，缓解中小企业流动资金紧张困难。-5‰/笔。'
        },
        {
          id: 3,
          src: require('./../assets/dgbank.jpg'),
          content: '免开户、免保证金、纯信用、额度高、可循环、操作简便、资料简单。留下联系方式后，全程我行协助操作，单笔手续费不超过5‰。'
        },
        {
          id: 4,
          src: require('./../assets/hfbank.png'),
          content: '建行银行为中标企业签订合同后专项用于项目所需资金的融资服务， 快捷高效、费低额高。。'
        }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    //切换
    changeTab(n) {
      this.tab = n
    },
    // 查看详情
    checkDetail() {
      this.$router.push({ path: '/product_detail', query: { id: null } })
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  computed: {

  }
}
</script>

<style lang="less">
.loan-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .loan-body {
    .loan-swiper {
      color: #fff;

      .el-carousel__item {
        background: url(./../assets/banner_img1.jpg)no-repeat 0 0;
        padding: 50px 0 0 380px;
        box-sizing: border-box;

        h2 {
          font-size: 28px;
        }

        p {
          font-size: 18px;
          line-height: 34px;
          width: 800px;
        }

        .btns {
          box-sizing: border-box;
          width: 158px;
          height: 40px;
          line-height: 38px;
          margin-top: 38px;
          text-align: center;
          font-size: 16px;
          color: #fff;
          border: 1px solid #9daee5;
          border-radius: 25px;
          transition: all .15s linear;
        }
      }
    }

    .loan-list {
      box-sizing: border-box;
      width: 60%;
      margin: 20px auto;

      .title {
        font-weight: bold;
        margin-right: 60px;
      }

      .loan-item {
        color: #313543;
        width: 120px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        margin-right: 20px;
      }

      .loan-item.active {
        color: #338BF3;
        border: 1px solid #338BF3;
        border-radius: 20px;
        background: #EDF7FF;
      }

      .loan-item:hover {
        cursor: pointer;
      }
    }

    .containter {
      padding: 20px;

      .bank-list {
        width: 100%;

        .bank-item {
          display: inline-block;
          border: 1px solid #DCDCDC;
          text-align: center;
          width: 32%;
          height: 280px;
          padding: 0 16px;
          transition: all .15s linear;
          vertical-align: top;
          box-sizing: border-box;
          margin-bottom: 20px;
          position: relative;

          div:first-child {
            width: 100%;
            height: 90px;
            text-align: center;
            position: relative;

            img {
              width: 70%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .text {
            font-size: 15px;
            color: #666;
            line-height: 24px;
            overflow: hidden;
            margin-top: 5px;
            text-align: left;
          }

          span {
            box-sizing: border-box;
            display: block;
            margin: 0 auto;
            width: 120px;
            text-align: center;
            height: 40px;
            line-height: 38px;
            border-radius: 25px;
            margin-top: 25px;
            font-size: 16px;
            color: #2979dc;
            border: 1px solid #2979dc;
            transition: all .15s linear;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }

          span:hover {
            cursor: pointer;
            color: #fff;
            background: #2979dc;
          }
        }

        .bank-item:hover {
          border-color: #2979dc;
          box-shadow: 0 0 10px #E7ECF2;
        }

        .bank-item:nth-child(3n+2) {
          margin: 0 2%;
        }

        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
